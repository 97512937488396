import MemoryImg1 from '@/assets/img/tmp/memory/memory_img1@2x.png';
import MemoryImg1M from '@/assets/img/tmp/memory/memory_img1M@2x.png';
import MemoryImg2 from '@/assets/img/tmp/memory/memory_img2@2x.png';
import MemoryImg2M from '@/assets/img/tmp/memory/memory_img2M@2x.png';
import MemoryImg3 from '@/assets/img/tmp/memory/memory_img3@2x.png';
import MemoryImg3M from '@/assets/img/tmp/memory/memory_img3M@2x.png';
import MemoryImg4 from '@/assets/img/tmp/memory/memory_img4@2x.png';
import MemoryImg4M from '@/assets/img/tmp/memory/memory_img4M@2x.png';
import MemoryImg5 from '@/assets/img/tmp/memory/memory_img5@2x.png';
import MemoryImg5M from '@/assets/img/tmp/memory/memory_img5M@2x.png';
import MemoryImg6 from '@/assets/img/tmp/memory/memory_img6@2x.png';
import MemoryImg6M from '@/assets/img/tmp/memory/memory_img6M@2x.png';
import MemoryObj1 from '@/assets/img/tmp/memory/memory_obj1@2x.png';
import MemoryObj1M from '@/assets/img/tmp/memory/memory_obj1M@2x.png';
import MemoryObj2 from '@/assets/img/tmp/memory/memory_obj2@2x.png';
import MemoryObj3 from '@/assets/img/tmp/memory/memory_obj3@2x.png';
import Button from '@/components/Button';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import {
  SUPPORT_TERM_ONCE,
  SUPPORT_CATEGORY_ONCE_MEMORY,
} from '@/helpers/SupportHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { navigate, PageProps } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

const memoryData = [
  {
    id: 1,
    pcImage: MemoryImg1,
    mobileImage: MemoryImg1M,
    tit: `출생, 백일, 돌`,
    dec: `오늘은 우리 아이가 태어난 날! 아이와 함께 살아가는 전 세계 모든 어린이에게 전해진 나눔을 기억하며 살아갔으면 좋겠어요.`,
    link: `/`,
  },
  {
    id: 2,
    pcImage: MemoryImg2,
    mobileImage: MemoryImg2M,
    tit: `졸업, 입학`,
    dec: `졸업이라는 끝과 앞으로 다가올 새로운 시작을 더 의미있게 하고 싶어요.`,
    link: `/`,
  },
  {
    id: 3,
    pcImage: MemoryImg3,
    mobileImage: MemoryImg3M,
    tit: `결혼, 연애`,
    dec: `두 사람이 하나가 된 날, 저희뿐만 아니라 다른 어린이들도 함께 행복했으면 좋겠어요.`,
    link: `/`,
  },
  {
    id: 4,
    pcImage: MemoryImg4,
    mobileImage: MemoryImg4M,
    tit: `입사, 보너스, 승진`,
    dec: `입사 3년 차, 늦었지만 이제서야 다른 사람을 돌아볼 여유가 생겨 마음을 나눠보려고 합니다.`,
    link: `/`,
  },
  {
    id: 5,
    pcImage: MemoryImg5,
    mobileImage: MemoryImg5M,
    tit: `생일, 회갑`,
    dec: `저를 낳아주고 길러준 부모님께 감사하며 받아온 것들을 더 어려운 어린이를 위해 돕고싶어요.`,
    link: `/`,
  },
  {
    id: 6,
    pcImage: MemoryImg6,
    mobileImage: MemoryImg6M,
    tit: `기타 기념일`,
    dec: `좋아하는 가수의 데뷔 3주년이에요! 뜻을 함께하는 사람들과 더욱 의미있는 날이 되었으면 좋겠습니다.`,
    link: `/`,
  },
];

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `특별후원팀`,
    tel: `02-721-5155`,
    email: `major@unicef.or.kr`,
  },
];

const SectionTop = styled(Section)`
  ${Container} {
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 875px;
      height: 701px;
      background: url(${MemoryObj1}) center no-repeat;
      background-size: cover;
      position: absolute;
      left: -285px;
      top: -234px;
      z-index: -1;

      ${breakpoint(1160)} {
        width: 75.43vw;
        height: 60.43vw;
        left: -17.67vw;
        top: -20.17vw;
      }
      ${breakpoint(`mobile`)} {
        width: 100%;
        height: 111.04vw;
        left: 0;
        top: -72px;
        background-image: url(${MemoryObj1M});
      }
    }
  }
`;

const MemoryFlex = styled.div`
  margin: 0 -16px;
  margin-bottom: 64px;
  display: flex;
  align-items: center;
  position: relative;

  &:last-child {
    margin-bottom: 0 !important;
  }

  &:nth-child(2n + 0) {
    flex-direction: row-reverse;
  }

  .col-img,
  .col-dec {
    width: 50%;
    padding: 0 16px;
  }

  .col-dec {
    .inner-wrap {
      padding-left: 32px;
      max-width: 448px;

      ${Tit} {
        display: block;
        border-bottom: 1px solid #1cabe2;
        margin-bottom: 32px;
        padding-bottom: 10px;
      }

      .button {
        width: 100%;
        max-width: 256px;
        margin-top: 64px;
      }
    }
  }

  .col-img {
    img {
      width: 100%;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &:nth-child(3) {
    &::before {
      background-image: url(${MemoryObj2});
      width: 714px;
      height: 615px;
      left: 183px;
      top: 0;
    }
  }

  &:nth-child(5) {
    &::before {
      background-image: url(${MemoryObj3});
      width: 383px;
      height: 383px;
      left: -154px;
      top: 289px;
    }
  }

  ${breakpoint(1160)} {
    &:nth-child(3) {
      &::before {
        width: 61.55vw;
        height: 53.01vw;
        left: 15.478vw;
      }
    }

    &:nth-child(5) {
      &::before {
        width: 33.02vw;
        height: 33.02vw;
        left: -13.28vw;
        top: 24.91vw;
      }
    }
  }

  ${breakpoint(`mobile`)} {
    flex-wrap: wrap;
    margin-bottom: 48px;

    &::before {
      display: none;
    }

    .col-img,
    .col-dec {
      width: 100%;
      margin-bottom: 24px;

      .inner-wrap {
        padding: 0;
        max-width: 100%;

        .button {
          max-width: 100%;
          margin-top: 32px;
        }
      }
    }
  }
`;

const SectionInfo = styled(Section)`
  ${SectionHeader} {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${Button} {
      width: 352px;
    }
    ${breakpoint(`tablet`)} {
      margin-left: 0;
      margin-right: 0;
      h2 {
        padding: 0;
      }
      ${Button} {
        width: 100%;
        margin-top: 57px;
      }
    }
    ${breakpoint(`mobile`)} {
      display: block;
    }
  }
`;

const SectionSpecialDay = styled(Section)`
  background: #f8f9fa;
  padding: 40px 0;
  margin-top: 120px;

  ${SectionHeader} {
    padding-bottom: 24px;
  }
  article {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
    .dec,
    .blit-list {
      line-height: 1.7;
    }
    .tit {
      display: block;
      margin-bottom: 4px;
    }
    .dec {
      margin-top: 40px;
    }
  }

  ${breakpoint(`tablet`)} {
    margin-top: 60px;
    padding: 24px 0;

    ${SectionHeader} {
      padding-bottom: 16px;
      .header-dec {
        margin-top: 24px;
      }
    }
    article {
      margin-bottom: 32px;

      .dec {
        margin-top: 32px;
      }
    }
  }
`;

const SectionContact = styled(Section)`
  ${breakpoint(`tablet`)} {
    .contactus {
      padding: 0;
    }
  }
`;

const Memory: FC<PageProps> = ({ location }) => {
  const navigateDonate = () => {
    navigate(
      `/donate/?supportTerm=${SUPPORT_TERM_ONCE}&supportCategory=${SUPPORT_CATEGORY_ONCE_MEMORY}`,
    );
  };

  return (
    <LayoutWithTitle
      location={location}
      title="기념기부"
      description="for every child, a special day"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                <PageComponent id="title1">
                  유니세프가 여러분의 <br />
                  특별한 날을 축하드립니다
                </PageComponent>
              </Tit>
            </h2>
            <div
              className="head-dec"
              css={`
                margin-top: 32px;
                line-height: 1.7;
                ${breakpoint(640)} {
                  margin-top: 24px;
                }
              `}
            >
              <PageComponent id="content1">
                살면서 누구나 맞이하는 수많은 특별한 날, <br />
                유니세프와 함께 전 세계 어린이들에게도 행복을 나누어 주세요.
              </PageComponent>
            </div>
          </SectionHeader>

          <div className="memory-container">
            {memoryData.map((row) => (
              <MemoryFlex key={row.id}>
                <div className="col-img">
                  <Image pcSrc={row.pcImage} mobileSrc={row.mobileImage} />
                </div>
                <div className="col-dec">
                  <div className="inner-wrap">
                    <Tit size="s3" color="sky">
                      {row.tit}
                    </Tit>
                    <p>{row.dec}</p>
                    <Button
                      color="yellow"
                      className="button"
                      type="submit"
                      onClick={() => {
                        navigateDonate();
                      }}
                    >
                      후원하기
                    </Button>
                  </div>
                </div>
              </MemoryFlex>
            ))}
          </div>
        </Container>
      </SectionTop>
      <SectionSpecialDay>
        <Container>
          <article>
            <p className="dec">
              For further questions, please contact +82+2-721-5155, or email us
              at major@unicef.or.kr
            </p>
          </article>
        </Container>
      </SectionSpecialDay>
      <SectionInfo className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">기념기부 안내자료</Tit>
            </h2>
            <div>
              <Button
                outline
                ico="down"
                className="button"
                type="button"
                onClick={() => {
                  window.open(
                    `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYw%3D%3D&dispFileNm=memory_donation.pdf&svrFileNm=memory_donation.pdf`,
                  );
                }}
              >
                <span className="ico">다운로드</span>
              </Button>
            </div>
          </SectionHeader>
        </Container>
      </SectionInfo>
      <SectionContact className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} className="contactus" />
        </Container>
      </SectionContact>
    </LayoutWithTitle>
  );
};

export default Memory;
